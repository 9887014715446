<template>
    <div class="background">
        <div class="container">
            <svgicon name="logo" class="logo" />
            <svgicon name="logo-text" class="logo-text" />

            <el-form
                ref="form"
                auto-complete="on"
                :model="form"
                :rules="rules"
                class="card-box form"
                :class="{disabled: loading && loading}"
            >
                <!-- LOADING LOGIN METHODS -->
                <div
                    v-if="!loginMethods"
                    v-loading="true"
                    class="spinner"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0)"
                />

                <!-- GOOGLE LOGIN -->
                <el-button
                    v-else-if="loginMethods['google_auth']"
                    element-loading-spinner="el-icon-loading"
                    @click.prevent="handleGoogleLogin"
                >
                    Sign in with Google
                </el-button>

                <!-- PASSWORD LOGIN -->
                <template v-else>
                    <el-input
                        v-model="form.email"
                        name="email"
                        type="text"
                        auto-complete="off"
                        placeholder="Email"
                    />
                    <el-input
                        v-model="form.password"
                        name="password"
                        type="password"
                        auto-complete="off"
                        placeholder="Password"
                    />
                    <el-button
                        native-type="submit"
                        @click.prevent="handlePasswordLogin"
                    >
                        Sign in
                    </el-button>
                </template>
            </el-form>
        </div>
        <span class="copyright">
            Made with <heart :width="12" /> by CodeFish Studio
        </span>
    </div>
</template>

<script>
import {validateEmail, validatePassword} from '@/utils/validate';
import {firebaseApp} from '@/utils/firebase';

import Heart from '@/components/generic/Heart';
import firebase from 'firebase/app';

import {Notification} from 'element-ui';

import {getLoginMethods} from '@/api/info';

export default {
    name: 'login',
    components: {
        Heart,
    },
    data() {
        const validateUsername = (rule, value, callback) => {
            if (!validateEmail(value)) {
                callback(new Error('Invalid Username'));
            } else {
                callback();
            }
        };
        const validatePass = (rule, value, callback) => {
            const str = validatePassword(value);
            if (str) {
                callback(new Error(str));
            } else {
                callback();
            }
        };
        return {
            logo: require('@/assets/img/logo.png'),
            form: {
                username: null,
                password: null,
            },
            rules: {
                email: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateUsername,
                    },
                ],
                password: [
                    {required: true, trigger: 'blur', validator: validatePass},
                ],
            },
            loading: false,
            loginMethods: null,
        };
    },
    computed: {
        session_user() {
            return this.$store.getters.session_user;
        },
    },
    watch: {
        session_user: {
            handler(newVal, oldVal) {
                // refresh page if session user is loaded
                if (
                    firebaseApp.auth().currentUser &&
                    newVal &&
                    newVal !== oldVal
                ) {
                    this.$router.go();
                }
            },
            immediate: true,
        },
    },
    async created() {
        const res = await getLoginMethods();
        this.loginMethods = res.data;
    },
    methods: {
        handlePasswordLogin() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    firebaseApp
                        .auth()
                        .signInWithEmailAndPassword(
                            this.form.email.trim(),
                            this.form.password
                        )
                        .catch((err) => {
                            this.loading = false;
                            console.log('Error password login: ', err);
                            this.showLoginError(err);
                        });
                } else {
                    console.log('Form not valid.');
                    return false;
                }
            });
        },
        handleGoogleLogin() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    let provider = new firebase.auth.GoogleAuthProvider();
                    firebaseApp
                        .auth()
                        .signInWithPopup(provider)
                        .catch((err) => {
                            this.loading = false;
                            console.log('Error Google login: ', err);
                            this.showLoginError(err);
                        });
                } else {
                    console.log('Form not valid.');
                    return false;
                }
            });
        },
        showLoginError(err) {
            let message = 'Something went wrong while trying to login.';

            try {
                const details = JSON.parse(err.message);
                if (details.error.message === 'INVALID_LOGIN_CREDENTIALS')
                    message = 'The credentials seems to be invalid.';
                else if (details.error.message) message = details.error.message;
            } catch (e) {
                if (err.message) message = err.message;
            }

            Notification({
                type: 'error',
                title: 'Error',
                message,
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.background {
    background-color: $actual-black;
    width: 100%;
    height: 100vh;
    position: fixed;

    .disabled {
        opacity: 0.1;
        pointer-events: none;
    }

    .container {
        position: fixed;
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
            width: 70px;
            display: block;
            margin: 0 auto;
            fill: $red;
            margin-bottom: 25px;
        }

        ::v-deep .logo-text {
            width: 124px;
            display: block;
            margin: 0 auto;
            margin-bottom: 40px;

            g {
                fill: white;
            }
        }

        .form {
            width: 350px;
            max-width: 100%;
            padding: 35px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: opacity 0.25s;

            ::v-deep .spinner {
                width: 38px;
                height: 38px;

                .el-loading-spinner i {
                    color: white;
                }
            }

            ::v-deep .el-form-item__error {
                display: none;
            }

            ::v-deep .el-input {
                flex: 1;
                height: 100%;
                width: 100%;
                border-radius: 5px;
                margin-bottom: 12px;
                font-family: 'Rubik', sans-serif;
                font-size: 13px;
                color: white;
                background: $actual-dark-black;

                input:-webkit-autofill {
                    border-radius: 5px;
                    -webkit-text-fill-color: white;
                    -webkit-box-shadow: 0 0 0px 1000px $actual-dark-black inset;
                    border-radius: 0;
                }

                input {
                    text-transform: lowercase;
                    background: transparent;
                    border: none;
                    -webkit-appearance: none;
                    border-radius: 0px;
                    padding: 12px 0 12px 12px;
                    background-color: transparent;
                    color: white;
                }
            }

            .el-button {
                width: 100%;
                border: none;
                color: $actual-dark-black;
                font-weight: 500;
                background: white;
                transition: opacity 0.25s;
            }
        }

        .el-form-item__error {
            padding-top: 8px;
        }

        ::placeholder {
            color: rgba(white, 0.75);
        }

        :-ms-input-placeholder {
            color: rgba(white, 0.75);
        }

        ::-ms-input-placeholder {
            color: rgba(white, 0.75);
        }

        .el-form-item__error {
            color: white;
            font-weight: 400;
        }
    }

    .copyright {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: block;
        text-align: center;
        user-select: none;
        font-size: 13px;
        font-weight: 400;
        color: white;
        margin-bottom: 50px;

        svg {
            margin: 0 3px;
        }
    }
}
</style>
